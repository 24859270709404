import React from "react";
import { Grid } from "@mui/material";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import test from "./test.jpeg";
// import test1 from "./test1.jpeg";
// import test2 from "./test2.jpeg";

import banner1 from "./final1.jpeg";
import banner2 from "./final2.jpeg";
import banner3 from "./final3.jpeg";
import banner4 from "./final4.jpeg";
import banner5 from "./final5.jpeg";

import "./styles.css";
const ListPagePromotionalBanner = () => {
  const newArray = [
    // test, test1, test2
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
  ];
  return (
    <Grid container spacing={2} my={1} style={{}}>
      <Grid item xs={12} md={12}>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {/* {newArray?.map((item, index) => (
            <SwiperSlide
              key={index + item + index}
              style={{
                border: "1px solid red",
                height: "100px",
                width: "100px",
              }}
            >
              <img
                src={item}
                alt={index}
                sizes="banner"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </SwiperSlide>
          ))} */}
          {newArray?.map((item, index) => {
            const isMobile = window.innerWidth <= 768;
            const slideStyle = {
              height: isMobile ? "auto" : "400px",
            };
            const imgStyle = {
              width: "100%",
              objectFit: "contain",
            };

            return (
              <SwiperSlide
                key={index + item + "12+" + index}
                style={slideStyle}
              >
                <img src={item} alt={index} style={imgStyle} sizes="banner" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default ListPagePromotionalBanner;
