import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Term = () => {
  return (
    <>
      <Header />
      <Box sx={{ backgroundColor: "#f9f9f9", minHeight: "100vh", py: 10 }}>
        <Container maxWidth="md">
          <Paper elevation={3} sx={{ p: 8, borderRadius: 2 }}>
            <Typography
              variant="h4"
              component="h1"
              color="#000D6B"
              gutterBottom
            >
              Terms & Conditions
            </Typography>

            <Typography variant="body1" gutterBottom>
              Welcome to our website. These Terms and Conditions, along with any
              documents they reference, govern your use of our website, along
              with its associated sites, services, applications, and tools. By
              accessing or using our services, you agree to comply with and be
              bound by these Terms and Conditions. The website and its services
              are managed by Goinfinite Digital Services Pvt Ltd, which operates
              under the brand name Car Expert.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              1. Acceptance Of Terms
            </Typography>
            <Typography variant="body1" gutterBottom>
              By accessing or using our website and services, you confirm that
              you accept these Terms and Conditions in full. If you do not agree
              to these terms, please do not use our website or services.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              2. Company Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              The website, www.jantarides.com. For any inquiries or further
              information, please visit the "Contact Us" page on our website.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              3. Changes to Terms
            </Typography>
            <Typography variant="body1" gutterBottom>
              We may update these Terms and Conditions from time to time to
              reflect changes in our services or to comply with legal
              requirements. Any changes will be posted on this page, and your
              continued use of the website constitutes acceptance of the revised
              terms.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              4. Use of Services
            </Typography>
            <Typography variant="body1" gutterBottom>
              You agree to use our website and services only for lawful purposes
              and in accordance with these Terms and Conditions. You must not
              use our website in a way that may harm or disrupt its
              functionality, or in a manner that breaches any applicable laws or
              regulations.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              5. Intellectual Property
            </Typography>
            <Typography variant="body1" gutterBottom>
              All content and materials available on our website, including but
              not limited to text, graphics, logos, and images, are the property
              of Goinfinite Digital Services Pvt Ltd or its licensors and are
              protected by intellectual property laws. You may not reproduce,
              distribute, or otherwise use any content from our website without
              prior written permission.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              6. User Obligations
            </Typography>
            <Typography variant="body1" gutterBottom>
              You agree to provide accurate, current, and complete information
              as required for using our services. You are responsible for
              maintaining the confidentiality of your account details and for
              all activities that occur under your account.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              7. Limitation of Liability
            </Typography>
            <Typography variant="body1" gutterBottom>
              To the extent permitted by law, Goinfinite Digital Services Pvt
              Ltd will not be liable for any direct, indirect, incidental, or
              consequential damages arising from your use of our website or
              services. This includes, but is not limited to, any loss of data,
              loss of business, or financial losses.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              8. Links to Other Websites
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our website may contain links to third-party websites. We are not
              responsible for the content or practices of these external sites.
              Your use of such websites is at your own risk and subject to their
              respective terms and conditions.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              9. Termination
            </Typography>
            <Typography variant="body1" gutterBottom>
              We reserve the right to suspend or terminate your access to our
              website and services at our sole discretion, without notice, for
              any reason, including if you violate these Terms and Conditions.
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              10. Governing Law
            </Typography>
            <Typography variant="body1" gutterBottom>
              These Terms and Conditions are governed by and construed in
              accordance with the laws of Uttar Pradesh. Any disputes arising
              out of or in connection with these terms shall be subject to the
              exclusive jurisdiction of the courts in Gautam Budh Nagar [Uttar
              Pradesh].
            </Typography>

            <Typography
              variant="h5"
              component="h2"
              color="#000D6B"
              gutterBottom
              sx={{ mt: 8 }}
            >
              11. Contact Information
            </Typography>
            <Typography variant="body1" gutterBottom>
              For any questions or concerns regarding these Terms and
              Conditions, please visit the "Contact Us" page on our website for
              our contact details. By using our website, you acknowledge that
              you have read, understood, and agree to these Terms and
              Conditions.
            </Typography>
          </Paper>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default Term;
