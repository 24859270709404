import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  PhoneIphone,
  Lightbulb,
  Tv,
  WaterDrop,
  LocalGasStation,
  Umbrella,
} from "@mui/icons-material";
import {
  DirectionsCar,
  LocalShipping,
  AirportShuttle,
  Commute,
} from "@mui/icons-material";
import {
  Moving,
  Inventory,
  Warehouse,
  HomeRepairService,
} from "@mui/icons-material";
const SectionItem = ({ Icon, label, subLabel, link }) => (
  <Grid item xs={6} sm={4} md={2} component={Link} href={link} underline="none">
    <Box textAlign="center">
      <Icon style={{ fontSize: 50, color: "#757575" }} />
      <Typography variant="body1" color="textPrimary">
        {label}
      </Typography>
      {subLabel && (
        <Typography variant="caption" color="textSecondary">
          {subLabel}
        </Typography>
      )}
    </Box>
  </Grid>
);

const PopularSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container>
      {/* First Section */}
      <Box
        my={4}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "center" : "flex-start"}
      >
        <Box mb={isMobile ? 2 : 0}>
          <Typography variant="h5" gutterBottom>
            Pickup & drop
          </Typography>
          <Typography variant="body2" gutterBottom>
            We pick up from your doorstep & drop to your destinations with Janta
            Rides
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <SectionItem Icon={DirectionsCar} label="Car Pickup" link="#" />
          <SectionItem Icon={LocalShipping} label="Delivery Van" link="#" />
          <SectionItem Icon={AirportShuttle} label="Shuttle Service" link="#" />
          <SectionItem Icon={Commute} label="Commute" link="#" />
        </Grid>
      </Box>

      {/* Second Section */}
      <Box
        my={4}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "center" : "flex-start"}
      >
        <Box mb={isMobile ? 2 : 0}>
          <Typography variant="h5" gutterBottom>
            Movers & Packers
          </Typography>
          <Typography variant="body2" gutterBottom>
            We help you shift in a new city without any hassle with Janta Rides.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <SectionItem Icon={LocalShipping} label="Moving Truck" link="#" />
          <SectionItem Icon={Moving} label="Relocation" link="#" />
          <SectionItem Icon={Inventory} label="Packing Items" link="#" />
          <SectionItem Icon={Warehouse} label="Storage" link="#" />
          <SectionItem
            Icon={HomeRepairService}
            label="Setup Services"
            link="#"
          />
        </Grid>
      </Box>
    </Container>
  );
};

export default PopularSection;

// import React from "react";
// import { Box, Container, Typography, Grid, Link } from "@mui/material";
// import {
//   PhoneIphone,
//   Lightbulb,
//   Tv,
//   WaterDrop,
//   LocalGasStation,
//   Umbrella,
// } from "@mui/icons-material";

// const SectionItem = ({ Icon, label, subLabel, link }) => (
//   <Grid item xs={6} sm={4} md={2} component={Link} href={link} underline="none">
//     <Box textAlign="center">
//       <Icon style={{ fontSize: 50, color: "#757575" }} />
//       <Typography variant="body1" color="textPrimary">
//         {label}
//       </Typography>
//       {subLabel && (
//         <Typography variant="caption" color="textSecondary">
//           {subLabel}
//         </Typography>
//       )}
//     </Box>
//   </Grid>
// );
// const PopularSection = () => {
//   return (
//     <Container>
//       <Box
//         my={4}
//         style={{
//           display: "flex",
//           flexDirection: "row",
//         }}
//       >
//         <Box>
//           <Typography variant="h5" gutterBottom>
//             Pickup & drop
//           </Typography>
//           <Typography variant="body2" gutterBottom>
//             we pickup from your doorstep & drop to your destinations with Janta
//             Rides
//           </Typography>
//         </Box>
//         <Grid container spacing={3}>
//           <SectionItem Icon={PhoneIphone} label="Mobile" link="#" />
//           <SectionItem Icon={Lightbulb} label="Electricity" link="#" />
//           <SectionItem Icon={Tv} label="DTH" link="#" />
//           <SectionItem Icon={WaterDrop} label="Water" link="#" />
//           <SectionItem Icon={LocalGasStation} label="Gas" link="#" />
//           <SectionItem Icon={Umbrella} label="Insurance" link="#" />
//         </Grid>
//       </Box>
//       <Box
//         my={4}
//         style={{
//           display: "flex",
//           flexDirection: "row",
//         }}
//       >
//         <Box>
//           <Typography variant="h5" gutterBottom>
//             Movers & Packers
//           </Typography>
//           <Typography variant="body2" gutterBottom>
//             We help you to siftin new city without any hassule with Janta Rides
//             🙂
//           </Typography>
//         </Box>
//         <Grid container spacing={3}>
//           <SectionItem Icon={PhoneIphone} label="Mobile" link="#" />
//           <SectionItem Icon={Lightbulb} label="Electricity" link="#" />
//           <SectionItem Icon={Tv} label="DTH" link="#" />
//           <SectionItem Icon={WaterDrop} label="Water" link="#" />
//           <SectionItem Icon={LocalGasStation} label="Gas" link="#" />
//           <SectionItem Icon={Umbrella} label="Insurance" link="#" />
//         </Grid>
//       </Box>
//     </Container>
//   );
// };

// export default PopularSection;
