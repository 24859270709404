import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from "@mui/material";

function EnquiryFormDialog({ open, handleClose }) {
  const [shiftOption, setShiftOption] = useState("Within City");

  const handleOptionChange = (event) => {
    setShiftOption(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Get the List of Top Packers And Movers
          </Typography>
          <Button onClick={handleClose}>&times;</Button>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          {/* Left Side - Form */}
          <Box sx={{ width: "60%", paddingRight: 2 }}>
            <TextField
              label="Name*"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Mobile Number*"
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
            />

            <FormLabel component="legend" sx={{ mb: 1 }}>
              Where do you want to shift?
            </FormLabel>
            <RadioGroup
              row
              value={shiftOption}
              onChange={handleOptionChange}
              sx={{ mb: 3 }}
            >
              <FormControlLabel
                value="Within City"
                control={<Radio />}
                label="Within City"
              />
              <FormControlLabel
                value="Outside City"
                control={<Radio />}
                label="Outside City"
              />
            </RadioGroup>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                backgroundColor: "#007BFF",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
                mb: 3,
              }}
            >
              Send Enquiry
            </Button>

            <Typography variant="body2" color="textSecondary">
              <ul>
                <li>
                  Your requirement is sent to the selected relevant businesses
                </li>
                <li>
                  Businesses compete with each other to get you the Best Deal
                </li>
                <li>You choose whichever suits you best</li>
                <li>Contact info sent to you by SMS/Email</li>
              </ul>
            </Typography>
          </Box>

          {/* Right Side - Ad/Info Section */}
          {/* <Box
            sx={{
              width: "35%",
              textAlign: "center",
              backgroundColor: "#f9f9f9",
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
              Create & Manage your Business for FREE
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
              Reach 150+ Million Users
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#007BFF",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Go to My Business
            </Button>
          </Box> */}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EnquiryFormDialog;
