import React, { useState, useEffect } from "react";
import BusinessDetailsPage from "../components/BusinessDetailsPage";
import ListHeader from "../components/ListHeader";
import SearchSection from "../components/SearchSection";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";
import { getBussinessDetails } from "../redux/searchBussiness/searchBussinessActions";
import { Box, CircularProgress, Typography } from "@mui/material";
const DetailsPage = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const { details, isLoading } = useSelector((state) => state.bussinessSearch);

  useEffect(() => {
    if (id) {
      dispatch(getBussinessDetails(id));
    }
  }, [id]);
  return (
    <>
      <ListHeader />
      <SearchSection />
      {isLoading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1301, // Higher than most components to cover them
          }}
        >
          <CircularProgress />
        </Box>
      ) : details === undefined ? (
        <Box
          style={{ display: "flex", margin: "auto", justifyContent: "center" }}
        >
          <Typography variant="h3" textAlign="center">
            No Data Found
          </Typography>
        </Box>
      ) : (
        <BusinessDetailsPage business={details} />
      )}
      <FAQ />
      <Footer />
    </>
  );
};

export default DetailsPage;
