import React from "react";
import Header from "../components/Header";
import SearchSection from "../components/SearchSection";
import PromotionalBanner from "../components/PromotionalBanner";
import CategorySection from "../components/CategorySection";
import Services from "../components/Services";
import PopularSection from "../components/PopularSection";
import FAQ from "../components/Faq";
import Footer from "../components/Footer";

const Homepage = () => {
  return (
    <>
      <Header />
      <SearchSection />
      <PromotionalBanner />
      <CategorySection />
      <Services />
      <PopularSection />
      <FAQ />
      <Footer />
    </>
  );
};

export default Homepage;
