import React from "react";
import { Grid, Box, Avatar, Typography, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { categories } from "../constants";

const CategorySection = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 2 }}>
      <Grid
        container
        gap={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="center"
        sx={{
          rowGap: { xs: 2, sm: 2, md: 2.5 },
          columnGap: { xs: 4, sm: 4, md: 4 },
        }}
      >
        {categories.map((category, index) => (
          <Grid
            item
            key={category.name + index}
            xs={4}
            sm={4}
            md={2}
            lg={1.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                width: "100%",
                textAlign: "center",
                boxShadow: 2,
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 1,
                cursor: "pointer",
                "&:hover": {
                  boxShadow: 4,
                },
              }}
              onClick={() =>
                navigate(`/listings/${category.name}`, { state: category.name })
              }
            >
              <Avatar sx={{ backgroundColor: "#ccc", color: "teal", mb: 1 }}>
                {category.icon}
              </Avatar>
              <Typography
                variant="body2"
                component="div"
                sx={{
                  fontSize: "0.85rem",
                  textAlign: "center",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {category.name}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CategorySection;
