import {
  DirectionsCar as CarIcon,
  AirportShuttle as BusIcon,
  TwoWheeler as AutoIcon,
  LocalShipping as TruckIcon,
  Moving as MoversPackersIcon,
  Commute as PickupIcon,
  CardTravel as TravelIcon,
  DirectionsTransit as TemboIcon,
  SportsMotorsports as ChampionIcon,
  EmojiTransportation as MexIcon,
} from "@mui/icons-material";

export const categories = [
  { name: "Pickup", icon: <PickupIcon /> },
  { name: "Car", icon: <CarIcon /> },
  { name: "Bus", icon: <BusIcon /> },
  { name: "Mex", icon: <MexIcon /> },
  { name: "Champion", icon: <ChampionIcon /> },
  { name: "Auto", icon: <AutoIcon /> },
  { name: "Movers & Packers", icon: <MoversPackersIcon /> },
  { name: "Tembo travels", icon: <TemboIcon /> },
  { name: "Canter loader", icon: <TruckIcon /> },
  { name: "Lodar", icon: <TruckIcon /> },
  { name: "Tata 407", icon: <TruckIcon /> },
];

export const statelist = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];
