import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
const ListHeader = () => {
  const navigate = useNavigate();
  const handleCallClick = () => {
    const mobileNumber = 8800702886;

    if (mobileNumber) {
      // Open the dialer with the specified number
      window.location.href = `tel:${mobileNumber}`;
    } else {
      alert("No contact number available");
    }
  };
  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{ borderBottom: "1px solid #eee" }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          // flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        {/* Logo */}
        <Typography
          onClick={() => navigate("/")}
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#FF6600",
            flex: { xs: "1 1 100%", md: "unset" },
            textAlign: "left",
          }}
        >
          Janta Rides
        </Typography>

        {/* Contact and Login Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
            alignItems: "center",
            mt: { xs: 2, md: 0 },
            width: { xs: "100%", md: "auto" },
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Button
            variant="text"
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: { xs: "14px", md: "inherit" },
              display: "flex",
              alignItems: "center",
              gap: 0.5, // Add some space between the icon and the text
            }}
            onClick={handleCallClick}
          >
            <span role="img" aria-label="phone">
              📞
            </span>{" "}
            8800702886
          </Button>

          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              fontSize: { xs: "14px", md: "16px" }, // Adjust font size for better readability
              // padding: { xs: "8px 12px", md: "10px 16px" }, // Adjust padding for visual balance
              width: { xs: "100%", md: "auto" },
              borderRadius: "8px", // Slightly rounder corners for better aesthetics
              borderColor: "primary.main", // Consistent border color
            }}
          >
            Login / Sign Up
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ListHeader;
