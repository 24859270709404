import React, { useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Button,
  Chip,
  IconButton,
  Tabs,
  Tab,
  Grid,
  CardContent,
} from "@mui/material";
import RecommendIcon from "@mui/icons-material/Recommend";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import ShareIcon from "@mui/icons-material/Share";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/css/free-mode";

import "swiper/css/thumbs";
import { getAddress } from "../utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "./BussinessDetailsStyle.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

const BusinessDetailsPage = ({ business }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const handleSendEnquiry = () => {
    // Construct the mailto link
    const email = "example@example.com";
    const subject = "Enquiry about the Product";
    const body = "Dear Team,\n\nI would like to inquire about...";

    // Open the default email client
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  const handleWhatsAppClick = () => {
    // WhatsApp phone number (in international format, without leading '+')
    const phoneNumber = "918800702886"; // Replace with your desired phone number
    const message = "Hello, I would like to inquire about...";

    // Construct WhatsApp URL
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappURL, "_blank");
  };

  const handleCallClick = () => {
    const mobileNumber = business?.contactDetails?.mobileNumber || "";

    if (mobileNumber) {
      // Open the dialer with the specified number
      window.location.href = `tel:${mobileNumber}`;
    } else {
      alert("No contact number available");
    }
  };
  console.log("src={business?.photos[0]", business);
  console.log("src={business?.ph", business?.photos?.[0]);

  return (
    <Box sx={{ width: "100%", p: { xs: 0.5 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card sx={{ borderRadius: 2, mb: 2 }}>
            <CardContent>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <RecommendIcon />
                      <Typography variant="h6" fontWeight="bold">
                        {business?.businessName}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", alignItems: "center", mt: 0.5 }}
                    >
                      <Chip
                        label="4.4"
                        icon={<StarIcon style={{ color: "white" }} />}
                        size="small"
                        sx={{ backgroundColor: "green", color: "white", mr: 1 }}
                      />
                      <Typography variant="body2">24 Ratings</Typography>
                      <Chip
                        label="Verified"
                        icon={<VerifiedIcon />}
                        size="small"
                        sx={{ ml: 1 }}
                      />
                      <Chip
                        label="Service Provider"
                        size="small"
                        sx={{ ml: 1 }}
                      />
                      <Chip label="Claimed" size="small" sx={{ ml: 1 }} />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                      <LocationOnIcon fontSize="small" />
                      <Typography variant="body2" sx={{ ml: 0.5 }}>
                        {getAddress(business?.address)}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ ml: 2, color: "green" }}
                      >
                        Open 24 Hrs
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 2 }}>
                        2 Years in Business
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  gap: 1,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CallIcon />}
                  onClick={handleCallClick}
                >
                  {business?.contactDetails?.mobileNumber}
                </Button>
                <Button
                  onClick={handleWhatsAppClick}
                  variant="outlined"
                  startIcon={<WhatsAppIcon />}
                >
                  WhatsApp
                </Button>
                <IconButton onClick={handleSendEnquiry}>
                  <Button variant="contained" startIcon={<ShareIcon />}>
                    Send Enquiry
                  </Button>
                </IconButton>
              </Box>

              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <CallIcon sx={{ m: 0.5 }} />
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      // sx={{ textAlign: "left", m: 0.5 }}
                    >
                      {business?.businessName}
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mb: 1 }}
                    >
                      <img
                        src={business?.photos?.[0]}
                        alt={"imagelklllklk"}
                        style={{
                          width: "100px",
                          height: "100px",
                        }}
                        sizes="banner"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    mt: 0.5,
                  }}
                >
                  <Chip
                    label="4.6"
                    icon={<StarIcon style={{ color: "white" }} />}
                    size="small"
                    sx={{ backgroundColor: "green", color: "white", mr: 1 }}
                  />
                  <Typography variant="body2">632 Ratings</Typography>
                  <Chip
                    label="Verified"
                    icon={<VerifiedIcon />}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                </Box>
                <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                  {getAddress(business?.address)}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                  124 Enquiries Answered
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    mt: 2,
                    gap: 1,
                  }}
                >
                  <Button variant="contained" color="success">
                    07487986933
                  </Button>
                  <Button variant="outlined">WhatsApp</Button>
                </Box>
                <Button variant="contained" sx={{ mt: 2, width: "100%" }}>
                  Enquire Now
                </Button>
                <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                  Responds in 2 Hrs
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Contact</Typography>
            <Typography variant="body2">
              Building No.-F 205, Near Parking No 5,
              <br /> Transport Nagar, Lucknow - 226012
            </Typography>
            <Button startIcon={<ShareIcon />} sx={{ mt: 2 }}>
              Share
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Tabs
        value={0}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mt: 3 }}
      >
        <Tab label="Overview" />
        <Tab label="Services" />
        <Tab label="Business Details" />
        <Tab label="Reviews" />
      </Tabs>

      <Box sx={{ mt: 3 }} style={{}}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Photos
        </Typography>
        <Grid container spacing={2} sx={{ overflow: "hidden" }}>
          <Swiper
            style={{
              "--swiper-navigation-color": "#ddd",
              "--swiper-pagination-color": "#ddd",
              width: "90%",
              height: "300px",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {business?.photos?.map((imgUrl, index) => (
              <SwiperSlide>
                <img src={imgUrl} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
            style={{ width: "90%", height: "150px" }}
          >
            <Grid item xs={12} sm={6} md={3}>
              {business?.photos?.map((imgurl, index) => (
                <SwiperSlide key={index + imgurl}>
                  <img src={imgurl} />
                </SwiperSlide>
              ))}
            </Grid>
          </Swiper>
          {/* {business?.photos?.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index + service}>
              <Card>
               
                <CardMedia
                  component="img"
                  height="140"
                  image={service}
                  alt={`Service ${index + 1}`}
                />
              </Card>
            </Grid>
          ))} */}
        </Grid>
      </Box>
    </Box>
  );
};
export default BusinessDetailsPage;
