import React, { useEffect } from "react";
import ListHeader from "../components/ListHeader";
import ListPagePromotionalBanner from "../components/ListPagePromotionalBanner";
import ListPageHeader from "../components/ListPageHeader";
import BusinessCard from "../components/BussinessCard";
import Footer from "../components/Footer";
import SearchSection from "../components/SearchSection";
import { useDispatch, useSelector } from "react-redux";
import { searchBussiness } from "../redux/searchBussiness/searchBussinessActions";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import Header from "../components/Header";
import nodatafound from "./search.png";
const ListsPage = () => {
  const { location: userLocation } = useSelector(
    (state) => state.bussinessSearch
  );
  const { id } = useParams();
  const { data, success, isLoading } = useSelector(
    (state) => state.bussinessSearch
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchBussiness({ searchBuss: id, area: userLocation ?? "" }));
  }, []);

  return (
    <>
      <Header />
      <SearchSection />
      <ListPagePromotionalBanner />
      <ListPageHeader data={data} />
      {isLoading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1301, // Higher than most components to cover them
          }}
        >
          <CircularProgress />
        </Box>
      ) : data?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: { xs: "10px", sm: "20px" }, // Responsive padding
            margin: "auto",
          }}
        >
          {/* <Typography variant="h3" textAlign="center">
            No Data Found
          </Typography> */}
          <img
            src={nodatafound}
            alt="No Data Found"
            style={{
              maxWidth: "100%",
              height: "auto",
              width: { xs: "80%", sm: "60%", md: "40%" }, // Responsive width
            }}
          />
        </Box>
      ) : (
        data?.map((item, index) => (
          <BusinessCard key={index + "index" + "4578"} data={item} />
        ))
      )}
      <Footer />
    </>
  );
};

export default ListsPage;
