// import { useMutation, useQuery, useQueryClient } from "react-query";
// import { BusinessesSearchAPI } from "./apis";
// import axios from "axios";

// // Define a function to fetch data from the API

// export const useBusinessSearch = (searchBuss, area) => {
//   // Using useQuery to fetch data based on searchBuss and area
//   return useQuery(["businesses", { searchBuss, area }], fetchBusinesses, {
//     enabled: false, // Only fetch when searchBuss is not empty
//     // enabled: !!searchBuss, // Only fetch when searchBuss is not empty
//     onError: (error) => {
//       console.error("Error fetching businesses:", error);
//     },
//   });
// };

export const getAddress = (address) => {
  if (!address) return "";

  const { blockNumber, streetName, area, landmark, city, state, pincode } =
    address;

  // Constructing the address string
  return `${blockNumber}, ${streetName}, ${area}, ${landmark}, ${city}, ${state}, ${pincode}`;
};
