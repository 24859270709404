import React from "react";
import { Grid } from "@mui/material";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import banner1 from "./final1.jpeg";
import banner2 from "./final2.jpeg";
import banner3 from "./final3.jpeg";
import banner4 from "./final4.jpeg";
import banner5 from "./final5.jpeg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";
const PromotionalBanner = () => {
  const newArray = [banner1, banner2, banner3, banner4, banner5];
  return (
    <Grid container spacing={2} style={{ marginTop: 1 }}>
      <Grid item xs={12} md={12}>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {newArray?.map((item, index) => {
            // Inline styles for img and SwiperSlide
            const isMobile = window.innerWidth <= 768;
            const slideStyle = {
              height: isMobile ? "auto" : "400px", // Change height based on screen size
            };
            const imgStyle = {
              width: "100%",
              objectFit: "contain", // Ensure the image covers the space without distortion
            };

            return (
              <SwiperSlide
                key={index + item + "12+" + index}
                style={slideStyle}
              >
                <img
                  src={item}
                  // src="https://dummyimage.com/1600x400/000/fff"
                  alt={index}
                  style={imgStyle}
                  sizes="banner"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Grid>
    </Grid>
  );
};

export default PromotionalBanner;
