import {
  SEARCH_BUSSINESS_REQUEST,
  SEARCH_BUSSINESS_SUCCESS,
  SEARCH_BUSSINESS_FAILED,
  SEARCH_BUSSINESS_LOCATION,
  GET_BUSSINESS_DETAILS_REQUEST,
  GET_BUSSINESS_DETAILS_SUCCESS,
  GET_BUSSINESS_DETAILS_FAILURE,
} from "./searchBussinessConstants";

const initialState = {
  success: false,
  data: [],
  isLoading: false,
  error: null,
  location: null,
  details: {},
};

export const bussinessSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_BUSSINESS_REQUEST:
      return { ...state, isLoading: true };
    case SEARCH_BUSSINESS_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
        error: null,
        data: action.payload,
      };
    case SEARCH_BUSSINESS_FAILED:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };

    case GET_BUSSINESS_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case GET_BUSSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
        error: null,
        details: action.payload,
      };
    case GET_BUSSINESS_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };
    case SEARCH_BUSSINESS_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};
