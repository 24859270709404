import React from "react";
import { Typography, Button, Box, Chip, Menu, MenuItem } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import StarIcon from "@mui/icons-material/Star";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import VerifiedIcon from "@mui/icons-material/Verified";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ListPageHeader = ({ data }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          px: 2,
          py: 1,
          backgroundColor: "#fff",
          borderBottom: "1px solid #eee",
        }}
      >
        <Button
          startIcon={<StarIcon />}
          sx={{ textTransform: "none", fontSize: { xs: "12px", sm: "14px" } }}
        >
          Top Rated
        </Button>
        {/* <Button
          sx={{ textTransform: "none", fontSize: { xs: "12px", sm: "14px" } }}
        >
          Quick Response
        </Button>
        <Button
          endIcon={<ArrowDropDownIcon />}
          sx={{ textTransform: "none", fontSize: { xs: "12px", sm: "14px" } }}
          onClick={handleMenuOpen}
        >
          Service Type
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>Service Type 1</MenuItem>
          <MenuItem onClick={handleMenuClose}>Service Type 2</MenuItem>
        </Menu>
        <Button
          endIcon={<ArrowDropDownIcon />}
          sx={{ textTransform: "none", fontSize: { xs: "12px", sm: "14px" } }}
        >
          Goods Type
        </Button>
        <Button
          endIcon={<ArrowDropDownIcon />}
          sx={{ textTransform: "none", fontSize: { xs: "12px", sm: "14px" } }}
        >
          Status
        </Button> */}
        <Chip
          label="Verified"
          icon={<VerifiedIcon />}
          variant="outlined"
          sx={{ fontSize: { xs: "10px", sm: "12px" } }}
        />
        {/* <Chip
          label="Deals"
          variant="outlined"
          sx={{ fontSize: { xs: "10px", sm: "12px" } }}
        /> */}
        <Chip
          label="Trusted"
          icon={<ThumbUpAltIcon />}
          variant="outlined"
          sx={{ fontSize: { xs: "10px", sm: "12px" } }}
        />
        <Button
          startIcon={<FilterListIcon />}
          sx={{ textTransform: "none", fontSize: { xs: "12px", sm: "14px" } }}
        >
          All Filters
        </Button>
      </Box>
    </Box>
  );
};

export default ListPageHeader;
