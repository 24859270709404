import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  IconButton,
  Chip,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import VerifiedIcon from "@mui/icons-material/Verified";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";
import { getAddress } from "../utils";
import OTPVerificationDialog from "./OtpVerify";
import MobileNumberLoginDialog from "./MobileNumberLoginDialog";
import EnquiryFormDialog from "./EnquiryFormDialog";

const BusinessCard = ({ data }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSendEnquiry = () => {
    // Construct the mailto link
    setOpen(true);

    // const email = "example@example.com";
    // const subject = "Enquiry about the Product";
    // const body = "Dear Team,\n\nI would like to inquire about...";

    // // Open the default email client
    // window.location.href = `mailto:${email}?subject=${encodeURIComponent(
    //   subject
    // )}&body=${encodeURIComponent(body)}`;
  };

  const handleWhatsAppClick = () => {
    // WhatsApp phone number (in international format, without leading '+')
    const phoneNumber = "918800702886"; // Replace with your desired phone number
    const message = "Hello, I would like to inquire about...";

    // Construct WhatsApp URL
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappURL, "_blank");
  };

  const handleCallClick = () => {
    const mobileNumber = data?.contactDetails?.mobileNumber || "";

    if (mobileNumber) {
      // Open the dialer with the specified number
      window.location.href = `tel:${mobileNumber}`;
    } else {
      alert("No contact number available");
    }
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          my: 1,
          borderRadius: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: 200 },
            height: { xs: 150, md: 200 },
            borderRadius: 1,
            mr: { md: 2, xs: 0 },
            mb: { xs: 2, md: 0 },
            overflow: "hidden",
          }}
        >
          <Swiper
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-navigation-size": "20px",
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {data?.photos?.map((item, index) => (
              <SwiperSlide key={index + item}>
                <img
                  src={item}
                  alt={`banner-${index}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
        {/* Right side - Content */}
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Title and Ratings */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: { xs: "wrap", md: "nowrap" },
              px: { xs: 1, md: 0 },
            }}
          >
            <Typography
              variant="h6"
              fontWeight="bold"
              onClick={() => navigate(`/detailspage/${data?._id}`)}
            >
              {data?.businessName}
            </Typography>
            <Chip
              label="4.8"
              icon={<ThumbUpAltIcon style={{ color: "white" }} />}
              size="small"
              sx={{
                backgroundColor: "green",
                color: "white",
                ml: 1,
                fontWeight: "bold",
              }}
            />
            <Typography variant="body2" sx={{ ml: 1, color: "gray" }}>
              234 Ratings
            </Typography>
            <VerifiedIcon sx={{ color: "blue", ml: 1 }} />
            <TrendingUpIcon sx={{ color: "orange", ml: 1 }} />
          </Box>

          {/* Location */}
          <Box
            sx={{
              px: { xs: 1, md: 0 },
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <LocationOnIcon fontSize="small" />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {getAddress(data?.address)}
            </Typography>
          </Box>

          {/* Services and Tags */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              mb: 1,
              px: { xs: 1, md: 0 },
            }}
          >
            <Chip label={data?.category?.name ?? "jantarides"} size="small" />
            <Chip label={data?.address?.city} size="small" />
            <Chip label="All India" size="small" />
          </Box>

          {/* Contact Buttons */}
          <Box
            sx={{
              px: { xs: 1, md: 0 },

              display: "flex",
              flexWrap: { xs: "wrap", md: "nowrap" },
              alignItems: "center",
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              startIcon={<PhoneIcon />}
              sx={{ backgroundColor: "green", mb: { xs: 1, md: 0 } }}
              onClick={handleCallClick}
            >
              {data?.contactDetails?.mobileNumber}
            </Button>
            <Button
              variant="outlined"
              sx={{ mb: { xs: 1, md: 0 } }}
              onClick={handleSendEnquiry}
            >
              Send Enquiry
            </Button>
            <IconButton
              sx={{
                border: "1px solid gray",
                borderRadius: 2,
                color: "green",
              }}
              onClick={handleWhatsAppClick}
            >
              <WhatsAppIcon />
            </IconButton>
          </Box>

          {/* Additional Info */}
          <Box
            sx={{
              px: { xs: 1, md: 0 },
              display: "flex",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Typography variant="body2" sx={{ color: "gray" }}>
              Responds in 35 Mins · 58 people recently enquired
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <EnquiryFormDialog open={open} handleClose={handleClose} />
      {/* <OTPVerificationDialog open={open} handleClose={handleClose} />
       */}
      {/* <MobileNumberLoginDialog open={open} handleClose={handleClose} /> */}
    </>
  );
};

export default BusinessCard;
