import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import logo from "./icon.svg";
const Header = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCallClick = () => {
    const mobileNumber = 8800702886;
    window.location.href = `tel:${mobileNumber}`;
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{ borderBottom: "1px solid #eee" }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
          {/* Logo */}
          <Box
            onClick={() => navigate("/")}
            sx={{
              flex: "unset",
              textAlign: "left",
            }}
          >
            <img
              src={logo}
              alt="Janta Rides"
              style={{ width: "100%", height: "60px" }}
            />
          </Box>

          <Button
            variant="text"
            color="primary"
            onClick={handleCallClick}
            sx={{
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              // gap: 0.5,
              // mr: 1,
            }}
          >
            <span role="img" aria-label="phone">
              📞
            </span>
            8800702886
          </Button>
          {/* Responsive Menu Button */}
          <Box sx={{ display: { xs: "flex", md: "none" }, ml: 1 }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem onClick={() => navigate("/about")}>
                <Typography textAlign="center">About us</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/contact")}>
                <Typography textAlign="center">Contact us</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate("/login")}>
                <Typography textAlign="center">Login / Sign Up</Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="center">Location</Typography>
              </MenuItem>
            </Menu>
          </Box>

          {/* Contact and Login Section for Desktop */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: 1,
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={handleCallClick}
              sx={{
                textTransform: "none",
                fontSize: "inherit",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <span role="img" aria-label="phone">
                📞
              </span>{" "}
              8800702886
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/login")}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                borderRadius: "8px",
                borderColor: "primary.main",
              }}
            >
              Login / Sign Up
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

// import React from "react";
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   Button,
//   Box,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// const Header = () => {
//   const navigate = useNavigate();
//   const handleCallClick = () => {
//     const mobileNumber = 8800702886;

//     if (mobileNumber) {
//       // Open the dialer with the specified number
//       window.location.href = `tel:${mobileNumber}`;
//     } else {
//       alert("No contact number available");
//     }
//   };

//   return (
//     <AppBar
//       position="static"
//       color="transparent"
//       elevation={0}
//       sx={{ borderBottom: "1px solid #eee" }}
//     >
//       <Toolbar
//         sx={{
//           justifyContent: "space-between",
//           // flexWrap: { xs: "wrap", md: "nowrap" },
//         }}
//       >
//         {/* Logo */}
//         <Typography
//           onClick={() => navigate("/")}
//           variant="h6"
//           sx={{
//             fontWeight: "bold",
//             color: "#FF6600",
//             flex: { xs: "1 1 100%", md: "unset" },
//             textAlign: "left",
//           }}
//         >
//           Janta Rides
//         </Typography>

//         {/* Contact and Login Section */}
//         {/* <Box
//           sx={{
//             display: "flex",
//             gap: 1,
//             alignItems: "center",
//             mt: { xs: 2, md: 0 },
//             width: { xs: "100%", md: "auto" },
//             justifyContent: { xs: "center", md: "flex-end" },
//           }}
//         >
//           <Button
//             variant="text"
//             color="primary"
//             sx={{
//               textTransform: "none",
//               fontSize: { xs: "14px", md: "inherit" },
//             }}
//           >
//             📞 8800702886
//           </Button>
//           <IconButton
//             sx={{
//               // display: { xs: "none", md: "block" },
//               ml: 1,
//             }}
//           >
//             <Button variant="outlined" sx={{ textTransform: "none" }}>
//               Login / Sign Up
//             </Button>
//           </IconButton>
//         </Box> */}
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: { xs: "column", md: "row" },
//             gap: 1,
//             alignItems: "center",
//             mt: { xs: 2, md: 0 },
//             width: { xs: "100%", md: "auto" },
//             justifyContent: { xs: "center", md: "flex-end" },
//           }}
//         >
//           <Button
//             variant="text"
//             color="primary"
//             sx={{
//               textTransform: "none",
//               fontSize: { xs: "14px", md: "inherit" },
//               display: "flex",
//               alignItems: "center",
//               gap: 0.5, // Add some space between the icon and the text
//             }}
//             onClick={handleCallClick}
//           >
//             <span role="img" aria-label="phone">
//               📞
//             </span>{" "}
//             8800702886
//           </Button>

//           <Button
//             variant="outlined"
//             sx={{
//               textTransform: "none",
//               fontSize: { xs: "14px", md: "16px" }, // Adjust font size for better readability
//               // padding: { xs: "8px 12px", md: "10px 16px" }, // Adjust padding for visual balance
//               width: { xs: "100%", md: "auto" },
//               borderRadius: "8px", // Slightly rounder corners for better aesthetics
//               borderColor: "primary.main", // Consistent border color
//             }}
//           >
//             Login / Sign Up
//           </Button>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };
// // <AppBar position="static" color="default">
// //   <Toolbar>
// //     <Typography
// //       onClick={() => navigate("/")}
// //       variant="h5"
// //       sx={{ fontWeight: "bold", color: "#FF6600" }}
// //     >
// //       Janta Rides
// //     </Typography>

// //     <Box
// //       sx={{ display: "flex", gap: 1 }}
// //       style={{ marginLeft: "auto", marginRight: 16 }}
// //     >
// //       <Button variant="text" color="primary" sx={{ textTransform: "none" }}>
// //         📞 8800702886
// //       </Button>
// //       <IconButton>
// //         <Button variant="contained" sx={{ textTransform: "none" }}>
// //           Login / Sign Up
// //         </Button>
// //       </IconButton>
// //     </Box>
// //   </Toolbar>
// // </AppBar>

// export default Header;
