import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, List, ListItem, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />

      <Box sx={{ padding: 8, maxWidth: "800px", margin: "0 auto" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="body1" gutterBottom>
          <strong>Effective Date:</strong> 20/10/2022
        </Typography>

        <Typography variant="body1" paragraph>
          At Jantarides, we respect your privacy and are committed to protecting
          the personal information you share with us. This Privacy Policy
          explains how we collect, use, and safeguard your information when you
          use our website and mobile application (referred to collectively as
          the "Services"). By using our Services, you agree to the collection
          and use of information in accordance with this Privacy Policy.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          1. Information We Collect
        </Typography>

        <Typography variant="body1" paragraph>
          We collect various types of information to provide and improve our
          Services, including:
        </Typography>

        <Typography variant="h6" gutterBottom>
          a. Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          When you register for our Services or book a service appointment, we
          may collect personal information such as:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>Name</ListItem>
          <ListItem>Email address</ListItem>
          <ListItem>Phone number</ListItem>
          <ListItem>Vehicle details (e.g., make, model, year)</ListItem>
          <ListItem>Payment information (if applicable)</ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          b. Location Data
        </Typography>
        <Typography variant="body1" paragraph>
          In order to provide our services, which include car servicing and car
          cleaning at your doorstep, we collect and use your device’s location
          data. This data is necessary to:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            Identify your current location for service delivery
          </ListItem>
          <ListItem>Estimate service times based on distance</ListItem>
          <ListItem>Assign service providers to your location</ListItem>
        </List>

        <Typography variant="body1" paragraph>
          You can enable or disable location services at any time through your
          device settings. However, disabling location access may impact our
          ability to provide doorstep services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          c. Log Data
        </Typography>
        <Typography variant="body1" paragraph>
          We collect log information automatically when you interact with our
          website or app, such as:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>IP address</ListItem>
          <ListItem>Browser type</ListItem>
          <ListItem>Device information</ListItem>
          <ListItem>Pages visited</ListItem>
          <ListItem>Time and date of visit</ListItem>
          <ListItem>Diagnostic data</ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect for the following purposes:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>To provide, operate, and maintain our Services</ListItem>
          <ListItem>
            To process your bookings and communicate service updates
          </ListItem>
          <ListItem>To personalize and improve your user experience</ListItem>
          <ListItem>To provide customer support</ListItem>
          <ListItem>To comply with legal obligations</ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          3. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your personal information with third parties in the
          following situations:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            <strong>Service Providers:</strong> We may share your information
            with third-party service providers who help us operate our business
            and provide the Services (e.g., payment processors, technicians, and
            customer service providers).
          </ListItem>
          <ListItem>
            <strong>Legal Requirements:</strong> We may disclose your
            information if required by law or to protect the rights, safety, or
            property of Jantarides, our users, or others.
          </ListItem>
          <ListItem>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or asset sale, your information may be transferred as
            part of the transaction.
          </ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take the security of your personal information seriously and
          implement industry-standard measures to protect it from unauthorized
          access, alteration, disclosure, or destruction. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee its absolute security.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          5. Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You have the following choices regarding your personal information:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            <strong>Access and Update:</strong> You can access and update your
            personal information through your account settings or by contacting
            us.
          </ListItem>
          <ListItem>
            <strong>Location Data:</strong> You can enable or disable location
            services in your device settings. However, please note that certain
            features of our Services may not function properly if location
            access is disabled.
          </ListItem>
          <ListItem>
            <strong>Marketing Communications:</strong> You can opt-out of
            receiving marketing communications by following the unsubscribe
            instructions in our emails or contacting us directly.
          </ListItem>
        </List>

        <Typography variant="h5" color="#26376F" gutterBottom>
          6. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar tracking technologies to collect
          information about your interactions with our website and app. You can
          manage cookie preferences through your browser settings, though
          disabling cookies may affect the functionality of our Services.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          7. Children’s Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our Services are not directed to individuals under the age of 13, and
          we do not knowingly collect personal information from children. If we
          become aware that we have collected personal information from a child
          under 13, we will take steps to delete it.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. When we do, we
          will notify you by updating the effective date at the top of this
          policy. We encourage you to review this policy periodically for any
          changes.
        </Typography>

        <Typography variant="h5" color="#26376F" gutterBottom>
          9. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </Typography>

        <List sx={{ paddingLeft: 4 }}>
          <ListItem>
            <strong>Email:</strong> jantarides@gmail.com
          </ListItem>
        </List>
      </Box>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
